import { useState } from "react";
import dynamic from "next/dynamic";

import { ImgixNextImage as Image } from "@components/imgixNextImage";

import { tagular } from "@cohesion/tagular";
import { DESTINATION_PROP_TYPES } from "@constants/places/proptypes";

const Gallery = dynamic(
  () => import("./gallery").then(mod => mod.HeroGallery),
  {
    ssr: false,
  }
);

export function Hero({ feature, secondary }) {
  const [galleryOpen, setGalleryOpen] = useState(false);

  return (
    <section className="lg:px-6 lg:pb-6">
      <div className="relative lg:rounded-lg h-screen-1/2 lg:min-h-144 overflow-hidden">
        <Image
          src={feature.url}
          alt={feature.alt}
          width={1920}
          height={640}
          imgixParams={{ w: 1920, h: 640, fit: "crop", crop: "faces,edges" }}
          priority
          className="object-cover w-full h-full"
        />

        {feature?.credit && (
          <p className="absolute left-6 bottom-2 transform -translate-y-4 w-content p-1 m-0 bg-black bg-opacity-80 text-white text-xs leading-tight rounded-sm">
            {feature.credit}
          </p>
        )}

        <div className="z-10 absolute top-0 w-full h-full bg-gradient-to-b from-black/40 via-black/10 to-transparent" />

        {secondary && secondary.length > 2 && (
          <button
            className="absolute z-20 bottom-0 right-0 m-8 rounded shadow-lg bg-white p-1"
            type="button"
            onClick={() => {
              setGalleryOpen(true);
              tagular("click", {
                actionOutcome: "open",
                outboundUrl: "null",
                webElement: {
                  location: "hero",
                  position: "gallery",
                  text: "show photos",
                  elementType: "button",
                },
              });
            }}
          >
            <span className="block pt-1 pb-2 text-xs text-center font-semibold leading-none">
              Gallery
            </span>
            <div className="relative w-20 h-20">
              <Image
                src={secondary[0].url}
                alt={secondary[0].alt}
                width={80}
                height={80}
                imgixParams={{ w: 80, h: 80, fit: "crop" }}
                className="rounded"
              />

              <span className="absolute right-0 bottom-0 z-30 m-1 rounded-sm bg-transparent-80 px-1 text-sm text-white">
                + {secondary.length}
              </span>
            </div>
          </button>
        )}
      </div>

      {galleryOpen && (
        <Gallery images={secondary} handleExit={() => setGalleryOpen(false)} />
      )}
    </section>
  );
}

Hero.propTypes = {
  feature: DESTINATION_PROP_TYPES.featuredImage, // eslint-disable-line react/require-default-props
  secondary: DESTINATION_PROP_TYPES.images, // eslint-disable-line react/require-default-props
};
