import PropTypes from "prop-types";

import { tagular } from "@cohesion/tagular";

import { Breadcrumbs } from "@components/breadcrumbs";

import { InfoFill } from "@icons";

import { SanitizeHTML } from "@components/sanitizeHtml";
import { ImgixNextImage } from "@components/imgixNextImage";
import { ArrowRight } from "@icons/arrowRight";

import { DESTINATION_PROP_TYPES } from "@constants/places/proptypes";
import bit from "@constants/best-in-travel/index.min";

import { ToolkitButtons } from "./toolkitButtons";
import { Driver } from "./driver";

export function Intro({ alert = "", breadcrumb = [], intro, articles, ctx }) {
  return (
    <section className="mt-8 mb-16 lg:mt-16 lg:mb-24">
      <div className="container">
        <div className="grid lg:grid-cols-3">
          {/**
           * Heading and description
           */}
          <div className="lg:col-span-2 lg:mr-8">
            <header>
              <h1 className="lg:inline text-3xl lg:text-6xl text-blue font-semibold">
                {ctx.title}
              </h1>

              {breadcrumb && (
                <div className="lg:inline lg:ml-4 -mb-4 xl:mb-0 text-xl">
                  <Breadcrumbs useComma inline breadcrumbs={breadcrumb} />
                </div>
              )}
            </header>

            {alert && (
              <aside className="my-12 flex">
                <InfoFill className="text-2xl text-gold-300 mr-4 shrink-0" />
                <SanitizeHTML elem="p" html={alert} />
              </aside>
            )}

            {ctx.BIT !== false && (
              <a
                href="/best-in-travel"
                className="no-underline flex items-center lg:w-content my-6 bg-black p-4 rounded"
                onClick={() => {
                  tagular("click", {
                    actionOutcome: "INTERNALLINK",
                    outboundUrl: "/best-in-travel",
                    webElement: {
                      location: "SECTION",
                      elementType: "BUTTON",
                      position: "DESTINATIONHUB",
                      text: "BITWINNERS",
                      name: "BITBADGE",
                    },
                  });
                }}
              >
                <div className="flex items-center">
                  <ImgixNextImage
                    src={bit.images.badge.src}
                    width={75}
                    height={75}
                  />
                  <h4 className="ml-[20px] text-white text-md w-2/3 md:w-1/2">
                    Check out this year&apos;s Best in Travel winners
                  </h4>
                </div>

                <ArrowRight className="text-white text-md" />
              </a>
            )}

            {intro && (
              <p className="max-w-2xl min-h-[90px] my-6  leading-loose text-black-400 text-sm lg:text-lg">
                {intro}
              </p>
            )}

            <ToolkitButtons articles={articles} className="mt-8" />
          </div>

          <div className="lg:row-span-2 mt-8 lg:mt-0">
            <Driver ctx={ctx} />
          </div>
        </div>
      </div>
    </section>
  );
}

Intro.propTypes = {
  ctx: DESTINATION_PROP_TYPES.ctx, // eslint-disable-line react/require-default-props
  intro: DESTINATION_PROP_TYPES.intro, // eslint-disable-line react/require-default-props
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      excerpt: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  alert: PropTypes.string,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
