const min = {
  title: "Best in Travel 2024",
  images: {
    badge: {
      src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2024/campaign/badge.png",
    },
  },
  destinations: [
    {
      name: "Mongolia",
      slug: "/mongolia",
      meta: { category: "countries", parent: "Asia", tags: [] },
    },
    {
      name: "India",
      slug: "/india",
      meta: { category: "countries", parent: "Asia", tags: [] },
    },
    {
      name: "St Lucia",
      slug: "/",
      meta: { category: "countries", parent: "Caribbean", tags: [] },
    },
    {
      name: "Mexico",
      slug: "/mexico",
      meta: { category: "countries", parent: "North America", tags: [] },
    },
    {
      name: "Morocco",
      slug: "/morocco",
      meta: { category: "countries", parent: "Africa", tags: [] },
    },
    {
      name: "Chile",
      slug: "/chile",
      meta: { category: "countries", parent: "South America", tags: [] },
    },
    {
      name: "Benin",
      slug: "/benin",
      meta: { category: "countries", parent: "Africa", tags: [] },
    },
    {
      name: "Uzbekistan",
      slug: "/uzbekistan",
      meta: { category: "countries", parent: "Asia", tags: [] },
    },
    {
      name: "Pakistan",
      slug: "/pakistan",
      meta: { category: "countries", parent: "Asia", tags: [] },
    },
    {
      name: "Croatia",
      slug: "/croatia",
      meta: { category: "countries", parent: "Europe", tags: [] },
    },
    {
      name: "Montana",
      slug: "/usa/rocky-mountains/montana",
      meta: { category: "regions", parent: "USA", tags: [] },
    },
    {
      name: "Donegal",
      slug: "/ireland/county-donegal",
      meta: { category: "regions", parent: "Ireland", tags: [] },
    },
    {
      name: "Trans Dinarica Cycling Route",
      slug: "/news/trans-dinarica-cycling-trail-western-balkans-slovenia-croatia-albania-montenegro",
      meta: { category: "regions", parent: "Western Balkans", tags: [] },
    },
    {
      name: "Kangaroo Island",
      slug: "/australia/south-australia/kangaroo-island",
      meta: { category: "regions", parent: "South Australia", tags: [] },
    },
    {
      name: "Tuscany",
      slug: "/italy/tuscany",
      meta: { category: "regions", parent: "Italy", tags: [] },
    },
    {
      name: "País Vasco",
      slug: "/articles/planning-foodie-trip-bilbao-basque-country-spain",
      meta: { category: "regions", parent: "Spain", tags: [] },
    },
    {
      name: "Southern Thailand",
      slug: "/articles/help-me-lp-southern-thailand-hat-yai-phattalung-trang-betong",
      meta: { category: "regions", parent: "Thailand", tags: [] },
    },
    {
      name: "Swahili Coast",
      slug: "/articles/tanzania-swahili-coast-zanzibar-mafia-archipelago-kilwa-kisiwani",
      meta: { category: "regions", parent: "Tanzania", tags: [] },
    },
    {
      name: "Saalfelden Leogang",
      slug: "/articles/planning-hiking-saafelden-leogang-austria",
      meta: { category: "regions", parent: "Austria", tags: [] },
    },
    {
      name: "Far North Scotland",
      slug: "/articles/planning-hiking-trip-scotland-east-coast",
      meta: { category: "regions", parent: "Scotland", tags: [] },
    },
    {
      name: "Nairobi",
      slug: "/kenya/nairobi",
      meta: { category: "cities", parent: "Kenya", tags: [] },
    },
    {
      name: "Paris",
      slug: "/france/paris",
      meta: { category: "cities", parent: "France", tags: [] },
    },
    {
      name: "Montréal",
      slug: "/canada/montreal",
      meta: { category: "cities", parent: "Canada", tags: [] },
    },
    {
      name: "Mostar",
      slug: "/bosnia-and-hercegovina/southern-bosnia-and-hercegovina/mostar",
      meta: { category: "cities", parent: "Bosnia", tags: [] },
    },
    {
      name: "Philadelphia",
      slug: "/usa/pennsylvania/philadelphia",
      meta: { category: "cities", parent: "USA", tags: [] },
    },
    {
      name: "Manaus",
      slug: "/brazil/the-north/manaus",
      meta: { category: "cities", parent: "Brazil", tags: [] },
    },
    {
      name: "Jakarta",
      slug: "/indonesia/jakarta",
      meta: { category: "cities", parent: "Indonesia", tags: [] },
    },
    {
      name: "Prague",
      slug: "/czech-republic/prague",
      meta: { category: "cities", parent: "Czech Republic", tags: [] },
    },
    {
      name: "İzmir",
      slug: "/turkey/aegean-coast/izmir",
      meta: { category: "cities", parent: "Turkey", tags: [] },
    },
    {
      name: "Kansas City",
      slug: "/usa/great-plains/kansas-city",
      meta: { category: "cities", parent: "Missouri", tags: [] },
    },
    {
      name: "Ecuador",
      slug: "/ecuador",
      meta: { category: "sustainable", parent: "South America", tags: [] },
    },
    {
      name: "Spain",
      slug: "/spain",
      meta: { category: "sustainable", parent: "Europe", tags: [] },
    },
    {
      name: "Patagonia",
      slug: "/argentina/patagonia",
      meta: { category: "sustainable", parent: "Argentina & Chile", tags: [] },
    },
    {
      name: "Greenland",
      slug: "/greenland",
      meta: { category: "sustainable", parent: "Europe", tags: [] },
    },
    {
      name: "Wales' Trails",
      slug: "/articles/hiking-glyndwrs-way-wales-planning",
      meta: { category: "sustainable", parent: "Europe", tags: [] },
    },
    {
      name: "Caminho Português de Santiago",
      slug: "/articles/why-choose-portuguese-way-camino-de-santiago",
      meta: { category: "sustainable", parent: "Portugal", tags: [] },
    },
    {
      name: "Palau",
      slug: "/palau",
      meta: {
        category: "sustainable",
        parent: "Australia & Pacific",
        tags: [],
      },
    },
    {
      name: "Hokkaidō",
      slug: "/japan/hokkaido",
      meta: { category: "sustainable", parent: "Japan", tags: [] },
    },
    {
      name: "Baltic Trails",
      slug: "/articles/planning-baltic-trails-hiking-estonia-latvia-lithuania",
      meta: { category: "sustainable", parent: "Baltics", tags: [] },
    },
    {
      name: "South Africa",
      slug: "/south-africa",
      meta: { category: "sustainable", parent: "Africa", tags: [] },
    },
    {
      name: "Poland",
      slug: "/poland",
      meta: { category: "value", parent: "Europe", tags: [] },
    },
    {
      name: "The Midwest",
      slug: "/articles/first-time-guide-to-the-midwest",
      meta: { category: "value", parent: "USA", tags: [] },
    },
    {
      name: "Nicaragua",
      slug: "/nicaragua",
      meta: { category: "value", parent: "Central America", tags: [] },
    },
    {
      name: "Danube Limes",
      slug: "/articles/danube-limes-bulgaria-unesco-site",
      meta: { category: "value", parent: "Bulgaria", tags: [] },
    },
    {
      name: "Normandy",
      slug: "/france/normandy",
      meta: { category: "value", parent: "France", tags: [] },
    },
    {
      name: "Egypt",
      slug: "/egypt",
      meta: { category: "value", parent: "Africa", tags: [] },
    },
    {
      name: "Ikaria",
      slug: "/greece/ikaria",
      meta: { category: "value", parent: "Greece", tags: [] },
    },
    {
      name: "Algeria",
      slug: "/algeria",
      meta: { category: "value", parent: "Africa", tags: [] },
    },
    {
      name: "Southern Lakes & Central Otago",
      slug: "/new-zealand/dunedin-and-otago/central-otago",
      meta: { category: "value", parent: "New Zealand", tags: [] },
    },
    {
      name: "Night Trains",
      slug: "/articles/europes-best-night-trains",
      meta: { category: "value", parent: "Europe", tags: [] },
    },
  ],
};

export default min;
