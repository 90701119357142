import { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";

import { NextHead } from "@components/nextHead";
import { Ad } from "@components/ad";
import { Hero } from "@components/heroGallery";
import { LatestArticles } from "@components/latestArticles";

import { Intro } from "@pageComponents/destinations/intro";
import { Tagalong } from "@pageComponents/destinations/tagalong";
import { Attractions } from "@pageComponents/destinations/attractions";
import { GetYourGuideActivities } from "@pageComponents/destinations/getYourGuideActivities";
import { Planning } from "@pageComponents/destinations/planning";
import { Recirculation } from "@pageComponents/destinations/recirculation";
import { Books } from "@components/shopifyBooks";

import { DESTINATION_PROP_TYPES } from "@constants/places/proptypes";
import getYourGuideExclusionList from "@constants/getYourGuide/gygExclusionList";

import {
  validateDestination,
  destinationsPageAPI,
} from "@queries/destination/detail/api";
import { getAdTargetingObj } from "@utils/ads/targeting";

const DynamicElsewhereRequest = dynamic(() =>
  import("@components/elsewhere/request").then(mod => mod.ElsewhereRequest)
);

export default function DestinationPage({
  context,
  meta,
  featuredImage,
  images,
  intro,
  travelAlert,
  relatedPlaces,
  mustSeePois,
  planning,
  articles,
  disableAds,
  slug,
  breadcrumb,
  hasGyg,
}) {
  const [anchors, setAnchors] = useState([]);
  const tagalongWaypoint = useRef(null);

  const ctx = {
    ...context,
    location: "HUBS",
  };

  const getAnchors = () => {
    /** Collect and prepend sections via eyebrows */
    const eyebrows = Array.from(document.querySelectorAll("[data-anchor]"));
    eyebrows.forEach((eyebrow, i) => {
      /**
       * Instead of automatically bailing when 0i has already been prepended, let's just pop it off
       * This'll allow us to keep this formatting in case new anchors are added dynamically
       */
      let { innerHTML } = eyebrow;
      if (innerHTML[0] === "0") {
        innerHTML = innerHTML.split("/").pop();
      }
      // eslint-disable-next-line
      eyebrow.innerHTML = `0${i + 1} / ${innerHTML}`;
    });

    /** Build subnav items */
    setAnchors(
      eyebrows.map(eyebrow => {
        const { anchor, nav } = eyebrow.dataset;

        return {
          anchor,
          nav,
        };
      })
    );
  };

  useEffect(() => {
    getAnchors();
  }, []);

  return (
    <>
      <NextHead image={meta.image} metadata={meta.tags}>
        <title>{meta.title}</title>
      </NextHead>

      <Tagalong ctx={ctx} anchors={anchors} waypoint={tagalongWaypoint} />

      {featuredImage && <Hero feature={featuredImage} secondary={images} />}

      <Intro
        intro={intro}
        articles={planning.primary}
        alert={travelAlert}
        ctx={ctx}
        breadcrumb={breadcrumb}
      />

      <hr ref={tagalongWaypoint} className="border-none" />
      <Attractions mustSeePois={mustSeePois} ctx={ctx} />

      {!disableAds && (
        <Ad
          className="mt-16 lg:mt-24"
          placement="fullWidth"
          path="destinations/top"
        />
      )}
      {Array.isArray(planning.secondary) && planning.secondary[0] && (
        <Planning articles={planning.secondary} />
      )}

      {/**
       * Pulling elsewhere destinations from a hardcoded mapping
       * This is a temporary solution until we flesh out the Monarch rule more
       */}
      {ctx.elsewhere && (
        <DynamicElsewhereRequest
          elsewhereDestination={ctx.elsewhereData}
          isHub
          slug={slug}
        />
      )}

      {articles && (
        <LatestArticles eyebrow="Articles" articles={articles} ctx={ctx} />
      )}

      <Books ctx={ctx} />

      {hasGyg && <GetYourGuideActivities place={ctx.title} />}

      {Array.isArray(relatedPlaces) && relatedPlaces[0] && (
        <Recirculation places={relatedPlaces} ctx={ctx} />
      )}

      {!disableAds && (
        <Ad
          className="mt-24 mb-8"
          placement="fullWidth"
          path="destinations/bottom"
        />
      )}
    </>
  );
}

export async function getServerSideProps({ query, res }) {
  res.setHeader("Cache-Control", "no-cache, max-age=3600, must-revalidate");
  const { place } = query;
  /**
   * Note: place is either a string or array of strings, if Array always join with `/`
   */
  const slug = Array.isArray(place) ? place.join("/") : place || null;

  /**
   * check for file extension and exit early when true
   * prevents running a validation lookup on erroneous routes
   */
  if (!slug || slug.match(/\.([^.]*)$/)) {
    return {
      notFound: true,
    };
  }

  /**
   * Because DFE2 supports so many possible first-segment slugs, e.g. `/asia` through
   * `/zimbabwe`, we've configured the Next routing patterns to check for other pages
   * explicitly and allow destintions to be treated as fallbacks. This prevents us from
   * having to define and parse some 5000 pages, but creates the potential for any
   * erroneous url to be treated as a destination page - causing a string of queries to
   * be run with a path that should more-immediately 404.
   *
   * Here, we check for the validity of the url first to prevent something like
   * `/narnia` or `/worst-in-travel` from getting to all of the more taxing queries.
   */
  const { isValid, redirect, type } = await validateDestination({
    slug,
  });

  if (!isValid) {
    return {
      notFound: true,
    };
  }
  if (redirect) {
    return {
      redirect: {
        destination: redirect,
        permanent: true,
      },
    };
  }

  /**
   * Get page data
   */
  const data = await destinationsPageAPI({ slug, type });

  const targeting = getAdTargetingObj(data.context);

  /**
   * GetYourGuide check exclusion list for match - invert for UI boolean
   */
  const hasGyg = !(getYourGuideExclusionList?.[data.context.slug] || false);

  return {
    props: {
      ...data,
      disableAds:
        data.context.slug.includes("russia") ||
        data.context.slug.includes("ukraine"),
      adTargeting: targeting,
      hasGyg,
    },
  };
}

DestinationPage.propTypes = DESTINATION_PROP_TYPES;
