import { Carousel } from "@components/carousel";
import { PoiCard } from "@components/cards/poi";
import { withSave } from "@components/_hocs/withSave";
import { Eyebrow } from "@pageComponents/destinations/eyebrow";
import { Pageblock } from "@components/pageblock";

import { DESTINATION_PROP_TYPES } from "@constants/places/proptypes";

export function Attractions({ mustSeePois, ctx }) {
  if (!mustSeePois[0]) return null;

  const POICardWSave = withSave(PoiCard);

  return (
    <div className="py-6 lg:p-6">
      <Pageblock inset className="bg-blue-100 md:p-10 w-full lg:rounded-lg">
        <div className="container">
          <Eyebrow nav="Attractions">Attractions</Eyebrow>

          <h3 className="mt-4 mb-16 text-2xl lg:text-5xl">
            Must-see attractions
          </h3>
        </div>

        <Carousel
          pageSize={3}
          contained={false}
          scrollPadding={false}
          tagularPosition="MUSTSEE"
          link={{
            href: ctx ? `/${ctx?.slug}/attractions` : null,
            text: "View more attractions",
          }}
        >
          {mustSeePois.map(poi => (
            <POICardWSave
              key={poi.esid}
              tagularPosition="MUSTSEE"
              hocTagularCtx={{ name: "MUSTSEE" }}
              category="attractions"
              {...poi}
            />
          ))}
        </Carousel>
      </Pageblock>
    </div>
  );
}

Attractions.propTypes = {
  ctx: DESTINATION_PROP_TYPES.ctx, // eslint-disable-line react/require-default-props
  mustSeePois: DESTINATION_PROP_TYPES.mustSeePois, // eslint-disable-line react/require-default-props
};
