import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useInView } from "react-intersection-observer";
import cn from "classnames";

import { ImgixNextImage as Image } from "@components/imgixNextImage";
import { tagular } from "@cohesion/tagular";

import { Controls } from "./controls";

export function Books({ ctx }) {
  const { title, type, ancestry, slug } = ctx;

  const places = ancestry
    .reduce(
      (acc, ancestor, i) => {
        /**
         * Loop ancestry to find ancestor types we're concerned with
         */
        const { type: t } = ancestor.meta;
        if (t === "Country" || t === "City") {
          acc.push(ancestor.title);
        }

        if (i + 1 === ancestry.length && acc.length === 0) {
          /**
           * If were done looping ancestry and have not found a match, we're
           * a level above country, e.g. continent. In this case, dump the current
           * title into the accumulator
           */
          acc.push(title);
        }

        return acc;
      },
      /**
       * Set the default acc to "self" of the page, given it's either a
       * a city or country. If it's lower order, e.g. neighborhood, the data
       * we want will be in the ancestry.
       */
      type === "City" || type === "Country" ? [title] : []
    )
    .reverse()
    .join("/");

  const hasQueried = useRef(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [fauxCart, setFauxCart] = useState([]);

  const handleUpdateCart = (method, product) => {
    switch (method) {
      case "add": {
        setFauxCart(prev => [...prev, product]);
        break;
      }

      case "remove": {
        const next = fauxCart.filter(item => item.id !== product.id);
        setFauxCart(next);
        break;
      }

      default:
        throw new Error("`handleUpdateCart` received an unsupported method");
    }

    const parent = data.find(book =>
      book.variants.some(variant => variant.id === product.id)
    );

    tagular("product_click", {
      actionOutcome: method === "add" ? "ADDTOCART" : "REMOVEFROMCART",
      correlationId: "",
      product: {
        location: "HUBS MODULE_SHOP",
        name: parent.title,
        variant: product.title,
        sku: product.sku,
        price: Number(product.price.amount),
        quantity: 1,
        text: method === "add" ? "ADD TO CART" : "REMOVE FROM CART",
        brand: "shop",
        position: fauxCart.length + 1,
      },
      outboundUrl: "NULL",
    });
  };

  const router = useRouter();

  const handleCreateCart = async () => {
    const ids = fauxCart.map(item => item.id);

    await fetch("/api/shopify/cart/create", {
      method: "POST",
      body: JSON.stringify(ids),
    })
      .then(r => r.json())
      .then(json => {
        const cid = json.id.split("/").pop();

        tagular("cart_started", {
          cartContext: {
            cartInstanceId: cid,
            pageReferrer: router.asPath,
          },
          referrer: router.asPath,
        });

        tagular("product_click", {
          product: {
            location: "HUBS MODULE_SHOP",
            text: "GO TO CHECKOUT",
            position: 0,
            brand: "shop",
          },
        });

        router.push(json.checkoutUrl);
      })
      .catch(e => setError(e));
  };

  useEffect(() => {
    if (hasQueried.current) return;

    const get = async () => {
      const res = fetch(`/api/shopify/books/dest/${places.toLowerCase()}`)
        .then(r => r.json())
        .then(json => {
          setData(json.length ? json : null);
        })
        .catch(e => setError(e));

      hasQueried.current = true;
      return res;
    };

    get();
  }, [places]);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      tagular("product_view", {
        product: {
          location: "HUBS MODULE_SHOP",
          text: "VIEW IN SHOP",
          position: 0,
          brand: "shop",
        },
      });
    }
  }, [inView]);

  if (!hasQueried.current || (hasQueried.current && !data)) return null;

  return (
    <section ref={ref} className="relative my-12" data-testid="books-component">
      <div className="container">
        <h2 className="text-2xl lg:text-3xl font-display font-semibold">
          Build a memorable collection
        </h2>
        <p className="max-w-xl mt-3 mb-12 text-slate">
          Get to the heart of {title || "your adventure"} with one of our
          in-depth, award-winning guidebooks, covering maps, itineraries, and
          expert guidance.
        </p>
      </div>

      <div className="absolute bottom-1/4 left-0 -z-10 w-full h-2/5 md:h-1/2 translate-y-[11%] lg:translate-y-[8%] bg-topography" />

      <ul
        className="flex lg:justify-center items-end gap-2 lg:gap-6 overflow-x-scroll no-scrollbar mt-6 px-6 md:px-2 lg:px-0"
        data-testid="books"
      >
        {data.map((book, i) => (
          <li
            key={book.id}
            className="flex-shrink-0 w-4/5 md:w-2/5 lg:w-1/4 max-w-80 lg:max-w-96 group"
          >
            <div className="relative border-8 border-white shadow rounded-r-md">
              <Image
                src={book.images[0].src}
                loader={uri => uri.src}
                width={288}
                height={384}
                className="w-full object-cover grayscale-[25%] group-hover:grayscale-0 transition-all shadow-lg rounded-r-md"
              />

              <a
                href={`${book.url}?utm_source=lonelyplanet&utm_campaign=hubsdriver&utm_content=${slug}`}
                className="absolute bottom-0 left-1/2 -translate-x-1/2 mb-3 opacity-0 group-hover:opacity-100 transition-opacity px-4 py-2 rounded-pill shadow bg-white text-blue text-sm font-semibold"
                target="_neue"
                rel="noopener noreferrer"
                onClick={() =>
                  tagular("product_click", {
                    actionOutcome: "EXTERNALLINK",
                    correlationId: "",
                    product: {
                      location: "HUBS MODULE_SHOP",
                      text: "VISIT IN SHOP",
                      name: book.title,
                      position: i + 1,
                      brand: "shop",
                    },
                    outboundUrl: book.url,
                  })
                }
              >
                Visit in Shop
              </a>
            </div>

            <Controls
              className="mt-2"
              book={book}
              handleUpdateCart={handleUpdateCart}
            />
          </li>
        ))}
      </ul>

      <button
        type="button"
        data-testid="checkout-button"
        className={cn(
          "mt-6 block mx-auto disabled:bg-black-100 bg-blue-100 px-4 py-2 rounded transition-all",
          "text-blue font-semibold",
          fauxCart.length ? "opacity-100 visible" : "opacity-0 invisible"
        )}
        disabled={!fauxCart.length || error}
        onClick={handleCreateCart}
      >
        Go to checkout ({fauxCart.length} item{fauxCart.length !== 1 ? "s" : ""}
        )
      </button>
    </section>
  );
}

Books.propTypes = {
  ctx: PropTypes.shape({
    title: PropTypes.string.isRequired,
    parent: PropTypes.string,
    type: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    ancestry: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        meta: PropTypes.shape({ type: PropTypes.string }),
      })
    ),
  }).isRequired,
};
