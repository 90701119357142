import PropTypes from "prop-types";
import { Pageblock } from "@components/pageblock";
import { Heading } from "@pageComponents/destinations/heading";
import cn from "classnames";

export function GetYourGuideActivities({ place = null }) {
  return (
    <Pageblock className="container">
      <header className="mt-5 lg:mt-8 mb-10 lg:mb-14 flex flex-col lg:items-center">
        <p className="inline-block text-sm lg:text-base text-transparent bg-clip-text bg-gradient-to-r from-slate to-bit-pink font-mono uppercase tracking-wider">
          in partnership with getyourguide
        </p>
        <Heading
          className={cn("lg:text-center", {
            "max-w-6xl": place.length < 25,
          })}
        >
          <span className="break-all">Book popular activities</span>
          {place && (
            <>
              {" "}
              <span className="inline-block">in {place}</span>
            </>
          )}
        </Heading>
      </header>
      <div
        href="https://widget.getyourguide.com/default/activities.frame"
        data-gyg-locale-code="en-US"
        data-gyg-widget="activities"
        data-gyg-number-of-items="3"
        data-gyg-partner-id="MH9NGR8"
        data-gyg-cmp="destination-hub"
        data-gyg-q={place}
      />
    </Pageblock>
  );
}

GetYourGuideActivities.propTypes = {
  place: PropTypes.string,
};
