import { withIcon } from "@icons/_withIcon";

export const GuideWordmark = withIcon(
  () => (
    <>
      <path d="M55.2246 43.0185C52.3842 43.0185 49.9444 42.4707 47.9051 41.3751C45.8659 40.2795 44.3 38.8128 43.2076 36.9751C42.1515 35.1373 41.6235 33.1051 41.6235 30.8786V15.3458H49.1069V30.8786C49.1069 31.9742 49.38 32.9814 49.9262 33.9003C50.4724 34.7839 51.2007 35.4907 52.1111 36.0208C53.0579 36.551 54.0958 36.816 55.2246 36.816C56.3535 36.816 57.3732 36.551 58.2836 36.0208C59.2304 35.4907 59.9769 34.7839 60.5231 33.9003C61.0693 32.9814 61.3424 31.9742 61.3424 30.8786V15.3458H68.8258V30.8786C68.8258 33.1051 68.2796 35.1373 67.1871 36.9751C66.1311 38.8128 64.5834 40.2795 62.5442 41.3751C60.5413 42.4707 58.1015 43.0185 55.2246 43.0185Z" />
      <path d="M75.923 42.3824V15.3458H83.461V42.3824H75.923Z" />
      <path d="M103.541 43.0185C101.21 43.0185 99.1161 42.6651 97.2589 41.9583C95.4381 41.2161 93.9087 40.1912 92.6705 38.8835C91.4324 37.5405 90.4856 35.9855 89.8301 34.2184C89.1747 32.416 88.8469 30.4545 88.8469 28.334C88.8469 25.648 89.3931 23.2978 90.4856 21.2833C91.6145 19.2334 93.1804 17.6254 95.1832 16.4591C97.2225 15.2928 99.5895 14.7097 102.284 14.7097C103.45 14.7097 104.56 14.8687 105.616 15.1868C106.709 15.5049 107.692 15.9643 108.566 16.5651C109.44 17.1659 110.15 17.8728 110.696 18.6856H110.751V3.683H118.234V28.334C118.234 31.3027 117.633 33.9003 116.432 36.1269C115.23 38.3181 113.537 40.0145 111.352 41.2161C109.167 42.4177 106.563 43.0185 103.541 43.0185ZM103.541 36.816C105.034 36.816 106.308 36.4626 107.364 35.7558C108.42 35.0489 109.221 34.0947 109.768 32.8931C110.35 31.6915 110.642 30.3485 110.642 28.8641C110.642 27.3797 110.35 26.0367 109.768 24.8351C109.221 23.6335 108.42 22.6793 107.364 21.9724C106.308 21.2656 105.034 20.9122 103.541 20.9122C102.084 20.9122 100.809 21.2656 99.7169 21.9724C98.6609 22.6793 97.8415 23.6335 97.2589 24.8351C96.7126 26.0367 96.4395 27.3797 96.4395 28.8641C96.4395 30.3485 96.7126 31.6915 97.2589 32.8931C97.8415 34.0947 98.6609 35.0489 99.7169 35.7558C100.809 36.4626 102.084 36.816 103.541 36.816Z" />
      <path d="M139.09 42.3824C135.922 42.3824 133.154 41.7992 130.787 40.6329C128.457 39.4313 126.636 37.8056 125.325 35.7558C124.05 33.7059 123.413 31.4087 123.413 28.8641C123.413 26.0014 124.014 23.5098 125.216 21.3893C126.417 19.2688 128.056 17.6254 130.132 16.4591C132.207 15.2928 134.556 14.7097 137.178 14.7097C140.31 14.7097 142.841 15.3458 144.771 16.6181C146.737 17.8904 148.176 19.6045 149.086 21.7604C149.996 23.8809 150.452 26.2488 150.452 28.8641C150.452 29.2175 150.433 29.6416 150.397 30.1364C150.361 30.5959 150.306 30.9493 150.233 31.1967H131.388C131.679 32.2923 132.171 33.2112 132.863 33.9533C133.591 34.6955 134.483 35.261 135.539 35.6497C136.632 36.0032 137.852 36.1799 139.199 36.1799H146.956V42.3824H139.09ZM131.333 26.3195H142.968C142.895 25.648 142.768 25.0295 142.586 24.464C142.404 23.8632 142.149 23.3331 141.821 22.8736C141.493 22.4142 141.111 22.0254 140.674 21.7074C140.237 21.3893 139.727 21.1419 139.145 20.9652C138.562 20.7885 137.925 20.7001 137.233 20.7001C136.322 20.7001 135.503 20.8592 134.775 21.1772C134.083 21.4953 133.5 21.9194 133.027 22.4495C132.553 22.9797 132.171 23.5805 131.88 24.252C131.625 24.9235 131.443 25.6126 131.333 26.3195Z" />
      <path d="M79.6128 10.2568C78.9069 10.2568 78.2435 10.1268 77.6226 9.86681C77.0018 9.60679 76.4617 9.25392 76.0024 8.80818C75.5431 8.36244 75.1795 7.83829 74.9116 7.23572C74.6437 6.63315 74.5098 5.98931 74.5098 5.30419C74.5098 4.61908 74.6437 3.97524 74.9116 3.37267C75.1795 2.7701 75.5431 2.24594 76.0024 1.80021C76.4617 1.35447 77.0018 1.0016 77.6226 0.741582C78.2435 0.481569 78.9069 0.351562 79.6128 0.351562C80.3188 0.351562 80.9822 0.481569 81.603 0.741582C82.2239 1.0016 82.764 1.35447 83.2233 1.80021C83.6825 2.24594 84.0461 2.7701 84.314 3.37267C84.582 3.97524 84.7159 4.61908 84.7159 5.30419C84.7159 5.98931 84.582 6.63315 84.314 7.23572C84.0461 7.83829 83.6825 8.36244 83.2233 8.80818C82.764 9.25392 82.2239 9.60679 81.603 9.86681C80.9822 10.1268 80.3188 10.2568 79.6128 10.2568ZM79.1025 9.24154V8.27577C78.8219 8.27577 78.5816 8.17878 78.3817 7.98481C78.1818 7.79083 78.0819 7.55764 78.0819 7.28525V6.78998L75.6324 4.41272C75.6069 4.5613 75.5835 4.70988 75.5623 4.85846C75.541 5.00704 75.5304 5.15561 75.5304 5.30419C75.5304 6.30297 75.8685 7.17794 76.5446 7.92909C77.2208 8.68024 78.0734 9.11772 79.1025 9.24154ZM82.6236 7.97862C82.7937 7.79702 82.9468 7.60098 83.0829 7.39049C83.219 7.18 83.3317 6.96126 83.421 6.73427C83.5103 6.50727 83.5783 6.27408 83.6251 6.03471C83.6719 5.79533 83.6953 5.55183 83.6953 5.30419C83.6953 4.49526 83.4635 3.7565 83 3.08789C82.5365 2.41929 81.9177 1.9364 81.1438 1.63925V1.83735C81.1438 2.10975 81.0438 2.34293 80.8439 2.53691C80.6441 2.73089 80.4038 2.82788 80.1231 2.82788H79.1025V3.8184C79.1025 3.95873 79.0536 4.07635 78.9558 4.17128C78.858 4.26621 78.7368 4.31367 78.5922 4.31367H77.5716V5.30419H80.6334C80.778 5.30419 80.8992 5.35166 80.997 5.44658C81.0948 5.54151 81.1438 5.65913 81.1438 5.79946V7.28525H81.6541C81.8752 7.28525 82.0751 7.34922 82.2537 7.47716C82.4323 7.6051 82.5556 7.77226 82.6236 7.97862Z" />
      <path d="M37.0112 38.5273C35.0212 40.1169 32.8024 41.3419 30.3547 42.2022C27.9071 43.0437 25.3599 43.4645 22.7133 43.4645C20.6835 43.4645 18.7234 43.2121 16.8329 42.7071C14.9623 42.2209 13.2112 41.5289 11.5794 40.6312C9.94763 39.7149 8.45515 38.6302 7.10198 37.3771C5.7488 36.1054 4.59462 34.7028 3.63943 33.1693C2.68425 31.617 1.93801 29.9526 1.40072 28.1759C0.883329 26.3993 0.624634 24.5572 0.624634 22.6496C0.624634 20.742 0.883329 18.9093 1.40072 17.1513C1.93801 15.3934 2.68425 13.7476 3.63943 12.2141C4.59462 10.6619 5.7488 9.25923 7.10198 8.00622C8.45515 6.73451 9.94763 5.64981 11.5794 4.75213C13.2112 3.85445 14.9623 3.16249 16.8329 2.67625C18.7234 2.17131 20.6835 1.91883 22.7133 1.91883C25.3599 1.91883 27.9071 2.34897 30.3547 3.20925C32.8024 4.05082 35.0212 5.26643 37.0112 6.85607L32.5338 13.8692C31.2403 12.6349 29.7478 11.6717 28.0563 10.9798C26.3649 10.2691 24.5838 9.91379 22.7133 9.91379C20.8427 9.91379 19.0816 10.2504 17.4299 10.9237C15.7981 11.5969 14.3654 12.5133 13.1316 13.6728C11.8978 14.8136 10.9227 16.1601 10.2063 17.7124C9.48994 19.2459 9.13174 20.8917 9.13174 22.6496C9.13174 24.4263 9.48994 26.0907 10.2063 27.6429C10.9227 29.1952 11.8978 30.551 13.1316 31.7105C14.3654 32.8701 15.7981 33.7864 17.4299 34.4597C19.0816 35.1329 20.8427 35.4696 22.7133 35.4696C23.7879 35.4696 24.8326 35.348 25.8475 35.1049C26.8623 34.8618 27.8275 34.5251 28.7429 34.095V22.6496H37.0112V38.5273Z" />
    </>
  ),
  {
    width: "2em",
    viewBox: "0 0 151 44",
  }
);
