import PropTypes from "prop-types";

import { tagular } from "@cohesion/tagular";
import { DESTINATION_PROP_TYPES } from "@constants/places/proptypes";
import { Eyebrow } from "@pageComponents/destinations/eyebrow";
import { Heading } from "@pageComponents/destinations/heading";

import { Carousel } from "@components/carousel";
import { BasicCard } from "@components/cards/basic";
import { Button } from "@components/button";

export function Recirculation({ places, ctx }) {
  if (places.length < 1) return null;

  return (
    <section className="my-16 lg:my-24">
      <div className="container">
        <div className="flex justify-between items-end mt-6 mb-14">
          <div>
            <Eyebrow nav="Go Beyond">Go Beyond</Eyebrow>
            <Heading>{`${ctx.title} and beyond`}</Heading>
          </div>
          <Button
            href={ctx.parent ? `/${ctx.parent.slug}/places` : "/places"}
            className="hidden lg:inline mb-4"
            onClick={() =>
              tagular("click", {
                actionOutcome: "INTERNALLINK",
                outboundUrl: ctx.parent
                  ? `${ctx.parent.slug}/places`
                  : "/places",
                webElement: {
                  location: "SECTION",
                  elementType: "BUTTON",
                  position: "GOBEYOND",
                  text: `BEYOND${ctx.title}`,
                },
              })
            }
          >
            Beyond {ctx.title}
          </Button>
        </div>
      </div>

      <Carousel
        pageSize={4}
        contained={false}
        scrollPadding={false}
        tagularPosition="GOBEYOND"
      >
        {places.map(place => (
          <BasicCard
            key={place.esid}
            type="destination"
            href={`/${place.slug}`}
            title={place.title}
            image={place.featuredImage || {}}
            onClick={() =>
              tagular("click", {
                actionOutcome: "internallink",
                outboundUrl: place.slug,
                webElement: {
                  location: "cards",
                  position: "go beyond",
                  text: place.title,
                  elementType: "link",
                },
              })
            }
          />
        ))}
      </Carousel>
      <div className="lg:hidden text-center mt-4">
        <Button
          href={ctx.parent ? `/${ctx.parent.slug}/places` : "/places"}
          className="inline-block bg-white text-black mr-4"
          onClick={() =>
            tagular("click", {
              actionOutcome: "INTERNALLINK",
              outboundUrl: ctx.parent ? `${ctx.parent.slug}/places` : "/places",
              webElement: {
                location: "SECTION",
                elementType: "BUTTON",
                position: "GOBEYOND",
                text: `BEYOND${ctx.title}`,
              },
            })
          }
        >
          Beyond {ctx.title}
        </Button>
      </div>
    </section>
  );
}

Recirculation.propTypes = {
  ctx: DESTINATION_PROP_TYPES.ctx, // eslint-disable-line react/require-default-props
  places: PropTypes.arrayOf(
    PropTypes.shape({
      esid: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        alt: PropTypes.string,
        caption: PropTypes.string,
        credit: PropTypes.string,
        height: PropTypes.number,
        name: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number,
      }).isRequired,
    })
  ).isRequired,
};
