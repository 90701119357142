/**
 * TODO: Don't animate height
 *
 * This may take signifigant rearchitecture of the component, but animating height isn't
 * performant. Difficulty here is that a translating the latter li siblings does not
 * recalculate the expected height of the nav container, and it stays "open" but empty.
 */
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import throttle from "lodash/throttle";

import { ArrowUpFill, ChevronDownFill } from "@icons";
import { LogoGlobe } from "@icons/logos";

import { tagular } from "@cohesion/tagular";
import { keyify } from "@utils/keyify";

import { DESTINATION_PROP_TYPES } from "@constants/places/proptypes";

export function Tagalong({
  anchors = [],
  ctx = {},
  waypoint = { current: null },
}) {
  const navref = useRef(null);
  const [isStuck, setIsStuck] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const scrollToTop = () => {
    if (isOpen) setIsOpen(false);

    /* eslint-disable-next-line no-restricted-globals */
    scrollTo(0, 0);

    tagular("click", {
      actionOutcome: "internallink",
      outboundUrl: "null",
      webElement: {
        location: "navigation",
        position: "tabcont",
        text: "backtotop",
        elementType: "link",
      },
    });
  };

  useEffect(() => {
    const handleScroll = throttle(
      () => {
        /* eslint-disable-next-line react/prop-types */
        if (window.scrollY > waypoint.current.offsetTop) {
          if (!isStuck) setIsStuck(true);
        } else {
          setIsStuck(false);
        }
      },
      300,
      { trailing: true }
    );

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    /**
     * `anchors` requires a paint to generate and will be an empty array at first render.
     */
    if (!anchors.length) return;

    const navs = Array.from(document.querySelectorAll(`[data-anchor-li]`));

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          const { anchor } =
            entry.target.querySelector("[data-anchor]").dataset;
          const li = document.querySelector(`[data-anchor-li="${anchor}"]`);

          if (entry.isIntersecting) {
            li.classList.add("lg:border-blue");
            li.classList.remove("lg:border-transparent");

            navs
              .filter(nav => nav.dataset.anchorLi !== anchor)
              .forEach(nav => {
                nav.classList.remove("lg:border-blue");
                nav.classList.add("lg:border-transparent");
              });
          }
        }),
      {
        root: null,
        threshold: 0.51,
      }
    );

    /**
     * Get all of the available data-anchors, which are `Eyebrow` components, and use
     * that to generate a list of all of the parent sections said Eyebrows are in. These
     * will be passed as entries to an IntersectionObserver.
     */
    const sections = Array.from(document.querySelectorAll("[data-anchor]")).map(
      eyebrow => eyebrow.closest("section")
    );

    sections.forEach(section => {
      observer.observe(section);
    });

    /* eslint-disable-next-line consistent-return */
    return () => {
      sections.forEach(section => {
        observer.disconnect(section);
      });
    };
  }, [anchors]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [isOpen]);

  return (
    <>
      <nav
        ref={navref}
        className={cn(
          isStuck ? "opacity-100" : "opacity-0 -translate-y-full",
          "fixed top-0 z-40 w-full bg-white border-b border-black-200 transition-all duration-300"
        )}
      >
        <ul className="container lg:flex items-center">
          <li className="flex items-center justify-between xl:w-2/5">
            <div className="flex items-center">
              <a href="/">
                <LogoGlobe className="text-blue text-xl" />
              </a>
              <h3 className="lg:font-display font-semibold text-md lg:text-xl">
                {ctx.title}
              </h3>
            </div>
            <button
              type="button"
              className="lg:hidden p-4 text-black-300"
              onClick={() => {
                setIsOpen(!isOpen);

                tagular("click", {
                  actionOutcome: "expand",
                  outboundUrl: "null",
                  webElement: {
                    location: "navigation",
                    position: "tabcont",
                    text: "arrow",
                    elementType: "button",
                  },
                });
              }}
            >
              {isOpen ? (
                <ChevronDownFill className="rotate-180" />
              ) : (
                <ChevronDownFill />
              )}
            </button>
          </li>

          <li
            className={cn(
              "flex-grow bg-white transition-all duration-100 origin-top",
              "absolute lg:relative top-full left-0 w-full",
              isOpen
                ? "opacity-100 scale-y-100"
                : "opacity-0 scale-y-0 invisible lg:visible lg:opacity-100 lg:scale-y-100"
            )}
          >
            <div className="lg:flex justify-between">
              <ul className="lg:flex flex-grow">
                {anchors.map(a => {
                  const action = e => {
                    e.preventDefault();

                    /** Due to some eyebrows being contained in a relative parent, we
                     * can't simply scroll to them with an offset. Instead, grab the
                     * closest node parent which is a `<section>` and scroll to that.
                     *
                     * Note: This means that each page component _must_ have a section
                     * as the wrapping tag - which is fine as this is semantically
                     * appropriate DOM organization.
                     */
                    const eyebrow = document.querySelector(
                      `[data-anchor='${a.anchor}']`
                    );
                    const section = eyebrow.closest("section");

                    window.scrollTo({
                      top: section.offsetTop,
                      behaviour: "smooth",
                    });

                    tagular("click", {
                      actionOutcome: "internallink",
                      outboundUrl: "null",
                      webElement: {
                        location: "navigation",
                        position: "tabcont",
                        text: a.nav,
                        elementType: "link",
                      },
                    });
                  };

                  return (
                    <li
                      key={keyify(a.nav)}
                      className="text-center border-b-2 border-transparent py-4 capitalize"
                      data-anchor-li={a.anchor}
                    >
                      <a
                        role="button"
                        tabIndex={0}
                        className="text-center mx-3 text-lg lg:text-base font-semibold"
                        onClick={e => action(e)}
                        onKeyUp={e => {
                          if (e.key === "enter") {
                            action(e);
                          }
                        }}
                      >
                        {a.nav}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <button
                type="button"
                className="flex items-center flex-none lg:text-base mx-auto my-12 lg:my-0"
                onClick={scrollToTop}
              >
                <ArrowUpFill className="text-black-300 mr-3" /> Back to top
              </button>
            </div>
          </li>
        </ul>
      </nav>
      <div
        className={cn(
          "bg-transparent-60 fixed top-0 left-0 w-full h-screen z-20 transition-opacity",
          isOpen ? "opacity-100" : "invisible opacity-0"
        )}
      />
    </>
  );
}

Tagalong.propTypes = {
  ctx: DESTINATION_PROP_TYPES.ctx,
  /**
   * These are CSR in an effect hook
   */
  anchors: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string.isRequired,
      nav: PropTypes.string.isRequired,
    })
  ),
  /**
   * This is CSR via ref; the boundary which triggers the transform to show the subnav
   */
  waypoint: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element
    PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
    }),
  ]),
};
