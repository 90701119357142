import { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { CartPlus, CartMinus } from "@icons";

export function Controls({ book, handleUpdateCart, className = "" }) {
  const { variants, title } = book;
  const product = variants[0];

  const [inCart, setInCart] = useState(false);

  return (
    <div
      className={cn("flex justify-between gap-2 relative", className)}
      data-testid="controls-component"
    >
      <div className="relative w-full mb-2">
        <p className="font-semibold ml-4">
          {title.split("-")[0]}{" "}
          <span className="block text-xs font-normal text-blue">
            ${product.price.amount}
          </span>
        </p>
      </div>

      <button
        type="button"
        data-testid="cart-button"
        className={cn(
          "relative w-8 h-8 mr-4 flex justify-center items-center rounded-sm text-black-300",
          inCart
            ? "bg-green-100 hover:bg-red-100"
            : "hover:text-green hover:bg-green-100",
          { "text-green hover:text-red": inCart }
        )}
        onClick={() => {
          setInCart(!inCart);
          handleUpdateCart(inCart ? "remove" : "add", product);
        }}
      >
        {inCart ? (
          <CartMinus className="text-lg" />
        ) : (
          <CartPlus className="text-lg" />
        )}
      </button>
    </div>
  );
}

Controls.propTypes = {
  book: PropTypes.shape({
    title: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        price: PropTypes.shape({
          amount: PropTypes.string.isRequired,
        }).isRequired,
        inStock: PropTypes.bool.isRequired,
      })
    ).isRequired,
  }).isRequired,
  handleUpdateCart: PropTypes.func.isRequired,
  className: PropTypes.string,
};
