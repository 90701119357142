import cn from "classnames";
import PropTypes from "prop-types";

import {
  ArrowRight,
  BuildingsFeature,
  CalendarSolidFill,
  CarFill,
  CheckmarkFeature,
  DaytripFeature,
  FreeCircleFill,
  KidsFeature,
  LGBTQFill,
  TravelDocsFill,
  TrainFill,
  GlowingStarFill,
  ShopFill,
  SpendingDiariesFill,
  TrophyFill,
  WalletFeature,
  WheelChairFill,
} from "@icons";

import { tagular } from "@cohesion/tagular";

const icons = {
  "things-to-do": GlowingStarFill,
  "best-places-to-visit": TrophyFill,
  "time-to-visit": CalendarSolidFill,
  "things-to-know": CheckmarkFeature,
  "getting-around": TrainFill,
  "with-kids": KidsFeature,
  budget: WalletFeature,
  neighborhoods: BuildingsFeature,
  "day-trips": DaytripFeature,
  "free-things": FreeCircleFill,
  "visa-requirements": TravelDocsFill,
  "road-trips-experience": CarFill,
  "5-shops": ShopFill,
  "spending-diaries": SpendingDiariesFill,
  accessibility: WheelChairFill,
  "lgbtiq-travel": LGBTQFill,
};

export function ToolkitButtons({ className = "", articles = [] }) {
  if (!articles || !Array.isArray(articles) || !articles[0]) return null;

  return (
    <div className={className}>
      <div className="flex gap-4">
        {articles.map((article, i) => {
          const Icon = icons[article.tag];

          return (
            <a
              key={article.slug}
              href={`/articles/${article.slug}`}
              className="underline-none rounded-md flex items-center first:bg-green-100 last:bg-red-100"
              onClick={() => {
                tagular("click", {
                  actionOutcome: "internallink",
                  outboundUrl: `/articles/${article.slug}`,
                  webElement: {
                    location: "section",
                    position: "intro",
                    text: article.title,
                    elementType: "link",
                  },
                });
              }}
            >
              <article className="p-4 flex justify-between items-center">
                <Icon
                  mono
                  className={cn(
                    "flex-shrink-0 text-lg",
                    i === 0 ? "text-green" : "text-red-300"
                  )}
                />
                <div className="flex justify-between items-center">
                  <h3 className="flex-1 px-3 text-sm font-sans font-semibold capitalize">
                    {article.copy}
                  </h3>
                  <ArrowRight />
                </div>
              </article>
            </a>
          );
        })}
      </div>
    </div>
  );
}

ToolkitButtons.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      excerpt: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    }).isRequired
  ),
  className: PropTypes.string,
};
